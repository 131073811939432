$main-accent: #E2881D;
$infobar-accent: #E2881D;
$glow-color: rgba($infobar-accent, 0);
$panel-background: #100B1A;
$title-color: #0F2E0D;
$subtitle: #F3F3F3;
$break-title: #1D0D2B;
$break-accent: #ED3636;
$donation-flair: #0F2E0D;
$donation-flair-2: #9F1B1B;
$donation-flair-3: #0C4E52;
$donation-text: #F3F3F3;
$incentive-donation-percent: #201303;
$incentive-donation-percent-big: $incentive-donation-percent;
$break-incentive-title: #E2881D;
$break-background-title: #494949;
$logo-gradient-left: #F3F3F3;
$logo-gradient-right: #F3F3F3;
$donation-gradient-left: #F3F3F3;
$donation-gradient-right: #F3F3F3;
$donation-gradient-shadow: #4B4D51;
$donation-reason: $logo-gradient-right;
$donation-percent: $infobar-accent;
$donation-flair-objective: #ED3636;

@mixin glow($color: $glow-color, $blur: 8px) {
    text-shadow: $color 0 0 $blur;
}

@mixin shadow($color: #000, $blur: 4px) {
    text-shadow: $color 0 0 $blur;
}

@mixin gradient($color-left, $color-right, $angle, $leftPercent, $rightPercent) {
    background: $color-left;
    background: -webkit-linear-gradient($angle, $color-left $leftPercent, $color-right $rightPercent);
    background: -moz-linear-gradient($angle, $color-left $leftPercent, $color-right $rightPercent);
    background: linear-gradient($angle, $color-left $leftPercent, $color-right $rightPercent);    
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@mixin stroke($color: #11111116, $size: 1px) {
    text-shadow: calc(#{$size} * 1) calc(#{$size} * 0) 0 $color,
        calc(#{$size} * 0.9239) calc(#{$size} * 0.3827) 0 $color,
        calc(#{$size} * 0.7071) calc(#{$size} * 0.7071) 0 $color,
        calc(#{$size} * 0.3827) calc(#{$size} * 0.9239) 0 $color,
        calc(#{$size} * 0) calc(#{$size} * 1) 0 $color,
        calc(#{$size} * -0.3827) calc(#{$size} * 0.9239) 0 $color,
        calc(#{$size} * -0.7071) calc(#{$size} * 0.7071) 0 $color,
        calc(#{$size} * -0.9239) calc(#{$size} * 0.3827) 0 $color,
        calc(#{$size} * -1) calc(#{$size} * 0) 0 $color,
        calc(#{$size} * -0.9239) calc(#{$size} * -0.3827) 0 $color,
        calc(#{$size} * -0.7071) calc(#{$size} * -0.7071) 0 $color,
        calc(#{$size} * -0.3827) calc(#{$size} * -0.9239) 0 $color,
        calc(#{$size} * 0) calc(#{$size} * -1) 0 $color,
        calc(#{$size} * 0.3827) calc(#{$size} * -0.9239) 0 $color,
        calc(#{$size} * 0.7071) calc(#{$size} * -0.7071) 0 $color,
        calc(#{$size} * 0.9239) calc(#{$size} * -0.3827) 0 $color;
}

* {
    image-rendering: pixelated;
}