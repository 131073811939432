* {
  image-rendering: pixelated;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: teko;
}

.bar-height {
  height: 90px;
}

#background {
  z-index: -200;
  background: #100b1a;
  width: 1920px;
  height: 90px;
  position: absolute;
}

#gloss {
  background: url("info-bar-gloss.adba5440.png");
  width: 1920px;
  height: 90px;
  position: absolute;
}

#bar-container {
  justify-content: space-between;
  align-items: center;
  width: 1920px;
  display: flex;
}

#bar-trim {
  background: url("info-bar-tag.89f828aa.png");
  width: 1920px;
  height: 90px;
  position: absolute;
}

#guf-logos {
  background: url("info-bar-tag.89f828aa.png");
  flex-flow: column;
  justify-content: center;
  width: 1920px;
  display: flex;
  position: absolute;
}

#guf-tag {
  text-align: center;
  opacity: 0;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(90deg, #f3f3f3 0% 100%);
  -webkit-background-clip: text;
  width: 250px;
  height: 80px;
  font-family: teko-medium;
  font-size: 61px;
  line-height: 90px;
}

#infobar {
  flex-basis: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

#infobar-rows {
  flex-flow: column;
  align-items: center;
  width: 100%;
  max-width: 1350px;
  display: flex;
  position: absolute;
  top: 90px;
}

.infobar-row {
  text-transform: uppercase;
  color: #fff;
  filter: drop-shadow(0 0 2px #1115);
  margin-top: 0;
  padding-top: 18px;
  font-family: teko;
  font-size: 41px;
  position: relative;
}

.infobar-row .hl {
  color: #e2881d;
  text-transform: none;
}

#donations {
  flex-flow: column;
  flex-basis: 15%;
  justify-content: center;
  align-items: flex-end;
  width: 150px;
  margin-top: 10px;
  margin-right: 25px;
  display: flex;
  position: relative;
}

#donation-amount {
  z-index: 20;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(45deg, #f3f3f3 0% 100%);
  -webkit-background-clip: text;
  margin-top: -15px;
  font-family: teko;
  font-size: 48px;
}

#donation-amount-number {
  font-family: teko-medium;
}

#donation-amount-shadow {
  z-index: 10;
  margin-top: -15px;
  font-family: teko;
  font-size: 48px;
  position: absolute;
  top: 13px;
  right: -2px;
}

#donation-amount-number-shadow {
  color: #4b4d51;
  font-family: teko-medium;
}

#donation-reason {
  text-transform: uppercase;
  color: #f3f3f3;
  margin-top: -15px;
  font-family: teko;
  font-size: 20px;
}

#flag-container {
  width: 20px;
  height: 100%;
  margin-left: 263px;
  display: flex;
}

#flag-container > .flag {
  width: 10px;
  height: 100%;
}

.long-flag {
  color: #fff;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 350px;
  height: 100%;
  padding-right: 15px;
  display: flex;
  position: relative;
  left: -340px;
  transform: skew(18.5deg);
}

.long-flag > span {
  color: #000;
  opacity: .75;
  letter-spacing: -1px;
  margin-top: 7px;
  margin-right: 6px;
  font-family: teko-medium;
  font-size: 42px;
  transform: skew(-18.5deg);
}

#flag-piece-red > .long-flag {
  z-index: -30;
  background: linear-gradient(90deg, #b22424 0%, #d92c2c 100%);
}

#next-run {
  opacity: 0;
  flex-wrap: wrap;
  align-items: baseline;
  width: 1100px;
  display: flex;
  position: absolute;
  left: 375px;
  transform: skew(-18.5deg);
}

#next-run .game-category {
  margin-left: 10px;
}

.game-name {
  text-transform: uppercase;
  font-family: teko;
  font-size: 40px;
}

.game-category {
  opacity: 1;
  filter: drop-shadow(0 0 2px #1115);
  font-family: teko-light;
  font-size: 32px;
}

.game-category.alternate {
  opacity: 0;
  position: absolute;
  margin-left: 0 !important;
}

.game-runner {
  filter: drop-shadow(0 0 2px #1115);
  flex-basis: 100%;
  font-family: teko-light;
  font-size: 26px;
}

.game-runner.hide {
  position: absolute;
}

.game-runner-name {
  color: #e2881d;
}

#next-run .game-runner {
  margin-top: -16px;
}

#flag-piece-white > .long-flag {
  z-index: -20;
  background: linear-gradient(90deg, #ccc 0%, #f3f3f3 100%);
}

#flag-piece-white > .long-flag > span {
  opacity: .5;
  margin-right: 37px;
}

#flag-piece-green > .long-flag > span {
  opacity: 1;
  color: #193e11;
  margin-right: 37px;
}

.no-hl {
  filter: drop-shadow(0 0 2px #1115);
  color: #fff;
  font-family: teko-light;
}

#future-runs .no-hl {
  font-size: 20px;
}

#future-runs {
  text-shadow: 0 0 4px #000;
  flex-flow: wrap;
  place-content: center;
  align-items: center;
  width: 1180px;
  display: flex;
  position: absolute;
  left: 360px;
  transform: skew(-18.5deg);
}

.future-run, .incentive-data {
  flex-basis: 33%;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.future-run > .game-data, .incentive-data > .game-data {
  opacity: 0;
  flex-basis: 65%;
  align-items: baseline;
  margin-left: 10px;
}

.future-run > .game-data .game-category, .incentive-data > .game-data .game-category {
  margin-left: 10px;
}

.future-run > .game-time {
  opacity: 0;
  text-transform: uppercase;
  color: #e2881d;
  flex-basis: 22%;
}

.future-run .game-time-in {
  font-family: teko;
  font-size: 20px;
  position: relative;
  top: 10px;
}

.future-run .game-time-in:empty {
  height: 12px;
}

.future-run .game-time-estimate {
  font-family: teko-semibold;
  font-size: 32px;
  position: relative;
  top: -4px;
}

#next-run .game-name {
  filter: drop-shadow(0 0 2px #1115);
}

.future-run .game-name {
  filter: drop-shadow(0 0 2px #1115);
  text-overflow: ellipsis;
  font-family: teko;
  font-size: 20px;
  overflow: hidden;
}

.future-run .game-name.alternate {
  white-space: nowrap;
  width: 250px;
}

.future-run .game-category {
  font-family: teko-light;
  font-size: 20px;
}

.future-run .game-runner {
  margin-top: -3px;
  font-family: teko-light;
  font-size: 20px;
}

.future-run .game-runner-name {
  font-family: teko;
}

.arrow {
  opacity: 0;
  position: relative;
  top: -40.5px;
  left: -25px;
}

.hide {
  opacity: 0;
}

.hidden {
  display: none;
}

#future-runs .arrow-top {
  background: linear-gradient(90deg, #ed363600 50%, #ed36364d 100%);
  width: 400px;
  height: 45px;
  position: absolute;
  top: -5px;
  transform: skew(18.5deg);
}

#future-runs .arrow-bottom {
  background: linear-gradient(90deg, #ed363600 50%, #ed36364d 100%);
  width: 400px;
  height: 45px;
  position: absolute;
  top: 40px;
  transform: skew(-18.5deg);
}

.show {
  transition: opacity 1s;
  opacity: 1 !important;
}

.game-time {
  text-align: center;
  flex-flow: column;
  justify-content: center;
  display: flex;
}

.game-data {
  flex-wrap: wrap;
  display: flex;
}

#flag-piece-green > .long-flag {
  z-index: -10;
  background: linear-gradient(90deg, #3e872f 0%, #50ad3d 100%);
}

#flag-piece-darkblue > .long-flag {
  z-index: 300;
  background: linear-gradient(90deg, #000 0%, #060f04 100%);
  width: 20px;
  position: relative;
}

#isc-logo {
  opacity: 0;
  position: absolute;
  left: 15px;
}

.incentive-data {
  color: #e2881d;
  width: 1140px;
  position: absolute;
  top: -5px;
  left: 25px;
  overflow: hidden;
}

.incentive-data .game-data {
  text-shadow: 0 0 4px #000;
  opacity: 0;
  flex-direction: column;
  flex-basis: 22%;
  transform: translateX(-20px);
}

.incentive-data .game-data .game-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 250px;
  height: 35px;
  font-size: 32px;
  overflow: hidden;
}

.incentive-data .game-data .game-category {
  color: #fff;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 250px;
  margin-left: 0;
  font-size: 22px;
  overflow: hidden;
}

.incentive-data .fixed-data {
  justify-content: space-between;
  width: 761px;
  height: 81px;
  margin-left: 63px;
  display: none;
}

.incentive-data .fixed-data .fixed-incentive-progress {
  flex-direction: column;
  place-content: center;
  display: flex;
}

.incentive-data .fixed-data .fixed-incentive-progress .donation-bar-container {
  position: relative;
  overflow: hidden;
  transform: translateY(40px);
}

.incentive-data .fixed-data .fixed-incentive-progress .donation-totals {
  color: #fff;
  justify-content: space-between;
  font-family: teko;
  font-size: 24px;
  display: flex;
  transform: translateY(-35px);
}

.incentive-data .fixed-data .donation-bar-size {
  width: 760px;
  height: 35px;
}

.incentive-data .fixed-data .donation-bar-size-inner {
  width: 752px;
  height: 23px;
}

.incentive-data .fixed-data .donation-border {
  background: url("incentivo-border.2695b263.png");
  position: absolute;
}

.incentive-data .fixed-data .donation-background {
  background: url("incentivo-bg.0f93338c.png");
  position: absolute;
}

.incentive-data .fixed-data .donation-bar-progressbar {
  background: url("incentivo-bar.bd9cbbea.png");
  justify-content: flex-end;
  margin-top: 6px;
  margin-left: 3px;
  display: flex;
  position: absolute;
  transform: translateX(-705px);
}

.incentive-data .fixed-data .donation-bar-progressbar .percent {
  color: #201303;
  margin-top: -1px;
  margin-right: 6px;
  font-size: 20px;
}

.incentive-data .bar-data {
  justify-content: space-between;
  align-items: flex-end;
  width: 761px;
  height: 81px;
  margin-left: 43px;
  display: none;
}

.incentive-data .bar-data .no-options {
  opacity: 0;
  justify-content: center;
  align-items: center;
  width: 800px;
  height: 100%;
  display: none;
  position: absolute;
  transform: translateX(-40px);
}

.incentive-data .bar-data .no-options .text {
  color: #fff;
  font-size: 29px;
}

.incentive-data .bar-data .bar {
  background-image: url("incentivi-multipli-blockpng.bde7c020.png");
  width: 245px;
  margin-bottom: -90px;
}

.incentive-data .bar-data .bar .bar-name {
  text-transform: uppercase;
  color: #684c0f;
  margin-left: 10px;
  font-size: 26px;
  font-weight: 600;
}

.incentive-data .bar-data .bar .bar-total {
  color: #684c0f;
  margin-right: 17px;
  font-size: 20px;
  font-weight: 600;
}

.incentive-data .bar-data .bar .bar-text {
  color: #e2881d;
  justify-content: space-between;
  align-items: baseline;
  display: flex;
}

#arrow-incentive {
  transform: skew(-18.5deg);
}

#arrow-incentive .arrow-top {
  background: linear-gradient(90deg, #ed363600 50%, #ed36364d 100%);
  width: 300px;
  height: 45px;
  position: absolute;
  top: -5px;
  transform: skew(18.5deg);
}

#arrow-incentive .arrow-bottom {
  background: linear-gradient(90deg, #ed363600 50%, #ed36364d 100%);
  width: 300px;
  height: 45px;
  position: absolute;
  top: 40px;
  transform: skew(-18.5deg);
}
/*# sourceMappingURL=index.e4fc056e.css.map */
