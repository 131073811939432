@import 'colors.scss';

* {
    /*outline:1px solid lime;*/
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: teko;
}

.bar-height {
    height: 90px;
}

#background {
    position: absolute;
    width: 1920px;
    height: 90px;
    background: $panel-background;
    z-index: -200;
}

#gloss {
    position: absolute;
    width: 1920px;
    height: 90px;
    background: url(../resources/infobar/info-bar-gloss.png);
}

#bar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1920px;
}

#bar-trim {
    position: absolute;
    width: 1920px;
    height: 90px;
    background: url(../resources/infobar/info-bar-tag.png);
}

#guf-logos {
    width: 1920px;
    background: url(../resources/infobar/info-bar-tag.png);
    display: flex;
    flex-flow: column;
    justify-content: center;
    position: absolute;
}

#guf-tag {
    text-align: center;
    font-family: teko-medium;
    font-size: 61px;
    line-height: 90px;
    height: 80px;
    width: 250px;
    opacity: 0;

    @include gradient($logo-gradient-left, $logo-gradient-right, 90deg, 0%, 100%);
}

#infobar {
    flex-basis: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#infobar-rows {
    display: flex;
    width: 100%;
    max-width: 1350px;
    flex-flow: column;
    position: absolute;
    top: 90px;
    align-items: center;
}

.infobar-row {
    position: relative;
    font-family: 'teko';
    font-size: 41px;
    text-transform: uppercase;
    color: white;
    filter: drop-shadow(0px 0px 2px #11111155);
    margin-top: 0px;
    padding-top: 18px;
}

.infobar-row .hl {
    color: $infobar-accent;
    text-transform: none;
}

#donations {
    width: 150px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-end;
    margin-right: 25px;
    margin-top: 10px;
    flex-basis: 15%;
    position: relative;
}

#donation-amount {
    font-family: 'teko';
    font-size: 48px;
    margin-top: -15px;
    z-index: 20;

    @include gradient($donation-gradient-left, $donation-gradient-right, 45deg, 0%, 100%);
}

#donation-amount-number {
    font-family: 'teko-medium';
}

#donation-amount-shadow {
    position: absolute;
    font-family: 'teko';
    font-size: 48px;
    margin-top: -15px;
    z-index: 10;
    top: 13px;
    right: -2px;
}

#donation-amount-number-shadow {
    font-family: 'teko-medium';
    color: $donation-gradient-shadow;
}

#donation-reason {
    font-family: teko;
    font-size: 20px;
    text-transform: uppercase;
    color: $donation-reason;
    margin-top: -15px;
}

#flag-container {
    margin-left: 263px;
    width: 20px;
    height: 100%;
    display: flex;
}

#flag-container>.flag {
    width: 10px;
    height: 100%;
}

.long-flag {
    width: 350px;
    height: 100%;
    position: relative;
    left: -340px;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-right: 15px;

    transform: skew(18.5deg);
    color: #FFF;
}

.long-flag>span {
    transform: skew(-18.5deg);
    color: black;
    opacity: 0.75;
    font-family: teko-medium;
    font-size: 42px;
    margin-top: 7px;
    letter-spacing: -1;
    margin-right: 6px;
}

#flag-piece-red>.long-flag {
    background: rgb(178, 36, 36);
    background: linear-gradient(90deg, rgba(178, 36, 36, 1) 0%, rgba(217, 44, 44, 1) 100%);
    z-index: -30;
}

#next-run {
    position: absolute;
    left: 375px;
    width: 1100px;
    transform: skew(-18.5deg);
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    opacity: 0;

    .game-category {
        margin-left: 10px;
    }
}

.game-name {
    font-family: teko;
    font-size: 40px;
    text-transform: uppercase;
}

.game-category {
    font-family: teko-light;
    font-size: 32px;
    opacity: 1;
    filter: drop-shadow(0px 0px 2px #11111155);

    &.alternate {
        position: absolute;
        opacity: 0;
        margin-left: 0px !important;
    }
}

.game-runner {
    flex-basis: 100%;
    font-family: teko-light;
    font-size: 26px;
    filter: drop-shadow(0px 0px 2px #11111155);

    &.hide {
        position: absolute;
    }
}

.game-runner-name {
    color: $infobar-accent;
}

#next-run .game-runner {
    margin-top: -16px;
}

#flag-piece-white>.long-flag {
    background: rgb(204, 204, 204);
    background: linear-gradient(90deg, rgba(204, 204, 204, 1) 0%, rgba(243, 243, 243, 1) 100%);
    z-index: -20;
}

#flag-piece-white>.long-flag>span {
    margin-right: 37px;
    opacity: 0.5;
}

#flag-piece-green>.long-flag>span {
    margin-right: 37px;
    opacity: 1;
    color: #193e11;
}

.no-hl {
    font-family: teko-light;
    filter: drop-shadow(0px 0px 2px #11111155);
    color: #FFF;
}

#future-runs .no-hl {
    font-size: 20px;
}

#future-runs {
    @include shadow();
    position: absolute;
    left: 360px;
    width: 1180px;
    transform: skew(-18.5deg);
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: center;
    align-items: center;
}

.future-run,
.incentive-data {
    flex-basis: 33%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.future-run>.game-data,
.incentive-data>.game-data {
    flex-basis: 65%;
    opacity: 0;
    align-items: baseline;
    margin-left: 10px;

    .game-category {
        margin-left: 10px;
    }
}

.future-run>.game-time {
    flex-basis: 22%;
    opacity: 0;
    text-transform: uppercase;
    color: $infobar-accent;
}

.future-run .game-time-in {
    font-family: teko;
    font-size: 20px;
    position: relative;
    top: 10px;

    &:empty {
        height: 12px;
    }
}

.future-run .game-time-estimate {
    font-family: teko-semibold;
    font-size: 32px;
    position: relative;
    top: -4px;
}

#next-run .game-name {
    filter: drop-shadow(0px 0px 2px #11111155);
}

.future-run .game-name {
    font-family: teko;
    font-size: 20px;
    filter: drop-shadow(0px 0px 2px #11111155);
    text-overflow: ellipsis;
    overflow: hidden;

    &.alternate {
        white-space: nowrap;
        width: 250px;
    }
}

.future-run .game-category {
    font-family: teko-light;
    font-size: 20px;
}

.future-run .game-runner {
    font-family: teko-light;
    font-size: 20px;
    margin-top: -3px;
}

.future-run .game-runner-name {
    font-family: teko;
}

.arrow {
    position: relative;
    top: -40.5px;
    left: -25px;
    opacity: 0;
}

.hide {
    opacity: 0;
}

.hidden {
    display: none;
}

#future-runs .arrow-top {
    width: 400px;
    position: absolute;
    height: 45px;
    top: -5px;
    background: $break-accent;
    background: linear-gradient(90deg, rgba($break-accent, 0) 50%, rgba($break-accent, 0.3) 100%);
    transform: skew(18.5deg);
}

#future-runs .arrow-bottom {
    width: 400px;
    position: absolute;
    height: 45px;
    top: 40px;
    background: $break-accent;
    background: linear-gradient(90deg, rgba($break-accent, 0) 50%, rgba($break-accent, 0.3) 100%);
    transform: skew(-18.5deg);
}

.show {
    transition: opacity 1s;
    opacity: 1 !important;
}

.game-time {
    display: flex;
    flex-flow: column;
    justify-content: center;
    text-align: center;
}

.game-data {
    display: flex;
    flex-wrap: wrap;
}

#flag-piece-green>.long-flag {
    background: rgb(62, 135, 47);
    background: linear-gradient(90deg, rgba(62, 135, 47, 1) 0%, rgba(80, 173, 61, 1) 100%);
    z-index: -10;
}


#flag-piece-darkblue>.long-flag {
    background: rgb(9, 22, 7);
    background: linear-gradient(90deg, rgb(0, 0, 0) 0%, rgb(6, 15, 4) 100%);
    z-index: 300;
    position: relative;
    width: 20px;
}

#isc-logo {
    position: absolute;
    left: 15px;
    opacity: 0;
}

.incentive-data {
    position: absolute;
    color: $infobar-accent;
    overflow: hidden;
    width: 1140px;
    left: 25px;
    top: -5px;

    .game-data {
        @include shadow();
        opacity: 0;
        transform: translateX(-20px);
        flex-basis: 22%;
        flex-direction: column;

        .game-name {
            font-size: 32px;
            height: 35px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            width: 250px;
        }

        .game-category {
            color: #FFF;
            margin-left: 0px;
            font-size: 22px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            width: 250px;
        }
    }

    .fixed-data {
        display: none;
        margin-left: 63px;
        width: 761px;
        height: 81px;
        justify-content: space-between;

        .fixed-incentive-progress {
            display: flex;
            flex-direction: column;
            align-content: center;
            justify-content: center;

            .donation-bar-container {
                overflow: hidden;
                position: relative;
                transform: translateY(40px);
            }

            .donation-totals {
                display: flex;
                justify-content: space-between;
                color: #FFF;

                font-size: 24px;
                font-family: 'teko';
                transform: translateY(-35px);
            }
        }

        .donation-bar-size {
            width: 760px;
            height: 35px;
        }

        .donation-bar-size-inner {
            width: 752px;
            height: 23px;
        }

        .donation-border {
            position: absolute;
            background: url(../resources/incentives/fixed/incentivo-border.png);
        }

        .donation-background {
            position: absolute;
            background: url(../resources/incentives/fixed/incentivo-bg.png);
        }

        .donation-bar-progressbar {
            position: absolute;
            background: url(../resources/incentives/fixed/incentivo-bar.png);
            margin-top: 6px;
            display: flex;
            justify-content: flex-end;
            transform: translateX(-705px);
            margin-left: 3px;

            .percent {
                color: $incentive-donation-percent;
                margin-right: 6px;
                margin-top: -1px;
                font-size: 20px
            }
        }
    }

    .bar-data {
        display: none;
        margin-left: 43px;
        width: 761px;
        height: 81px;
        justify-content: space-between;
        align-items: flex-end;

        .no-options {
            position: absolute;
            transform: translateX(-40px);
            opacity: 0;
            display: none;
            justify-content: center;
            align-items: center;

            width: 800px;
            height: 100%;

            .text {
                color: #FFF;
                font-size: 29px;
            }
        }

        .bar {
            background-image: url(../resources/incentives/incentivi-multipli-blockpng.png);
            width: 245px;
            margin-bottom: -90px;

            .bar-name {
                text-transform: uppercase;
                font-size: 26px;
                margin-left: 10px;
                font-weight: 600;
                color: #684c0f;
            }

            .bar-total {
                font-size: 20px;
                margin-right: 17px;
                font-weight: 600;
                color: #684c0f;
            }

            .bar-text {
                color: $break-incentive-title;
                display: flex;
                justify-content: space-between;
                align-items: baseline;
            }
        }
    }
}

#arrow-incentive {
    transform: skew(-18.5deg);
}

#arrow-incentive .arrow-top {
    width: 300px;
    position: absolute;
    height: 45px;
    top: -5px;
    background: $break-accent;
    background: linear-gradient(90deg, rgba($break-accent, 0) 50%, rgba($break-accent, 0.3) 100%);
    transform: skew(18.5deg);
}

#arrow-incentive .arrow-bottom {
    width: 300px;
    position: absolute;
    height: 45px;
    top: 40px;
    background: $break-accent;
    background: linear-gradient(90deg, rgba($break-accent, 0) 50%, rgba($break-accent, 0.3) 100%);
    transform: skew(-18.5deg);
}